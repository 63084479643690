import { addBreadcrumb, captureException } from '@sentry/solidstart';
import { createSignal, ErrorBoundary as SolidErrorBoundary, onMount, Show, Suspense, createEffect } from 'solid-js';
import { isServer } from 'solid-js/web';
import { Button, Link, Container } from '@troon/ui';
import { twMerge } from '@troon/tailwind-preset/merge';
import { IconSquareWarning } from '@troon/icons/square-warning';
import { createAsync, useSearchParams } from '@solidjs/router';
import { CombinedError } from '@urql/core';
import { GraphQLError } from 'graphql';
import { getConfigValue } from '../modules/config';
import { getLogger } from '../middleware/logging';
import { Error404 } from '../graphql';
import { isVersionMismatch } from '../providers/visibility';
import { NotFoundContent } from '../partials/404';
import type { ComponentProps, JSX } from 'solid-js';

export function ErrorBoundary(
	props: Omit<ComponentProps<typeof SolidErrorBoundary>, 'fallback'> & { contained?: boolean; content?: JSX.Element },
) {
	const [controllerChanged, setControllerChanged] = createSignal(false);

	onMount(() => {
		if (isServer || !('serviceWorker' in navigator)) {
			return;
		}

		navigator.serviceWorker.addEventListener('controllerchange', (event) => {
			addBreadcrumb({ message: 'ServiceWorker controller change.', timestamp: event.timeStamp });
			setControllerChanged(true);
		});
	});

	return (
		<SolidErrorBoundary
			fallback={(error) => {
				if (error instanceof Error404 || error.name === 'Error404') {
					return <NotFoundContent />;
				}

				const content = props.content ?? (
					<Fallback contained={props.contained} error={error} controllerChanged={controllerChanged()} />
				);

				return props.contained ? <Container>{content}</Container> : content;
			}}
		>
			{props.children}
		</SolidErrorBoundary>
	);
}

function Fallback(props: { error: Error; contained?: boolean; controllerChanged: boolean }) {
	const [searchParams] = useSearchParams();
	const isMismatch = createAsync(async () => {
		if (searchParams.autoreload) {
			return false;
		}
		return isVersionMismatch();
	});

	onMount(() => {
		if (props.controllerChanged && !isServer) {
			addBreadcrumb({ message: 'Will reload due to ServiceWorker controller change.' });
			captureException(props.error);
			window.location.reload();
		} else {
			if (isServer) {
				getLogger().error({ error: props.error });
			}

			if (props.error instanceof CombinedError && props.error.graphQLErrors.length) {
				props.error.graphQLErrors.forEach((e) => {
					if (ignoredGraphQLMessages.find((i) => i.test(e.message))) {
						return;
					}
					const error = new GraphQLError(e.message, { extensions: e.extensions, path: e.path });
					error.stack = e.stack?.length ? e.stack : error.stack;
					captureException(error);
				});
			} else {
				captureException(props.error);
			}
		}
	});

	createEffect(() => {
		if (isMismatch()) {
			const url = new URL(window.location.href);
			url.searchParams.append('autoreload', '1');
			window.location.href = url.toString();
		}
	});

	return (
		<Suspense>
			<Show when={!isMismatch() && !props.controllerChanged && !isServer} fallback={null}>
				<h1
					class={twMerge(
						'flex flex-row items-center gap-2 font-semibold text-red-500',
						props.contained ? 'my-16 text-6xl' : 'my-8 text-4xl',
					)}
				>
					<IconSquareWarning />
					An error has occured
				</h1>
				<p class="mb-4">
					{(props.error instanceof CombinedError
						? ((props.error.graphQLErrors.find((e) => e.extensions?.displayMessage)?.extensions
								.displayMessage as string) ?? null)
						: null) ?? 'Something went wrong.'}
				</p>
				{getConfigValue('ENVIRONMENT', 'development') === 'development' ? (
					<pre class="mb-4 overflow-x-auto rounded-md bg-brand-900 p-4 font-mono text-white">
						{(props.error as Error).stack ?? props.error.toString()}
					</pre>
				) : null}
				<div class="flex flex-row">
					<div class="flex flex-row gap-4">
						<Button
							onClick={() => {
								window.location.reload();
							}}
						>
							Reload
						</Button>
						<Button appearance="secondary" as={Link} href="/" target="_self">
							Go home
						</Button>
					</div>
				</div>
			</Show>
		</Suspense>
	);
}

const ignoredGraphQLMessages: Array<RegExp> = [
	/failed to login/i,
	/forgot password request/i,
	/invalid confirmation code/i,
	/no rate found for tee time/i,
];
